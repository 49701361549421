<template>
  <div>
    <div class="main">
      <div class="title">
        <div>
          <i @click="goBack" class="iconfont icon-return"></i>
          <h2>{{ caseInfo.name }}</h2>
        </div>
        <div>
          <span v-if="!isSet" @click="batch">一括反映</span>
        </div>
      </div>
      <div class="content-box">
        <div class="top-setter">
          <div>
            <h3>作業工程設定</h3>
            <div class="add_lc">
              <!-- <span>
                <i class="el-icon el-icon-plus"></i>
              </span>
              <b></b> -->
            </div>
          </div>
        </div>
        <div class="set-content">
          <div>
            <el-select
              v-model="isSelected"
              size="small"
              @change="handelChange"
              multiple
              placeholder="選択して下さい"
            >
              <el-option
                v-for="item in list"
                :key="item.id"
                :label="item.position"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-tag v-for="tag in isSelected" :key="tag">
              {{ filterUser(tag) }}
            </el-tag>
          </div>
        </div>
        <div class="pages" >
          <ul>
            <li v-for="(item, index) in pageList" :key="index">
              <p class="disabled" v-if="item.status!=1"> asdasd</p>
              <div>
                <el-checkbox v-model="item.checked"
                  >第{{ item.num }}頁</el-checkbox
                >
              </div>
              <div v-if="false">
                <el-table
                  :data="
                    item.pageuserarr.length >= 2
                      ? item.pageuserarr
                      : item.sortArr
                  "
                  style="width: 100%"
                  size="mini"
                >
                  <el-table-column prop="position" label="職業">
                  </el-table-column>
                  <el-table-column prop="" label="メンバー">
                    <template scope="scope" v-if="item.pageuserarr.length == 1">
                      <el-select
                        v-model="item['value' + scope.$index]"
                        size="mini"
                        placeholder="選択して下さい"
                        @change="
                          change(
                            item['value' + scope.$index],
                            index,
                            item,
                            'value' + scope.$index
                          )
                        "
                      >
                        <el-option
                          v-for="(i,vc) in scope.row.userarr"
                          :key="vc"
                          :label="i.nickname"
                          :value="`${i.userid}+${sortArr[scope.$index].id}`"
                        >
                        </el-option>
                      </el-select>
                    </template>
                    <template scope="scope" v-else>
                      <span> {{ scope.row.nickname }} </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="id" label="操作">
                    <template scope="scope">
                      <i
                        @click="removeLine(item, scope.row)"
                        class="el-icon-remove-outline"
                      ></i>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="getPos.length">
                <el-table :data="getPos[index].pos"
                style="width: 100%">
                  <el-table-column width="160px" prop="pos" label="職業">
                  </el-table-column>
                  <el-table-column  width="220px" prop="userArr" label="人员">
                    <template scope="scope">
                      <el-select v-model="scope.row.checked">
                        <el-option v-for="(i,vc) in scope.row.userArr" :key="vc"
                          :label="i.nickname"
                          :value="i.userid">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column  width="70px" prop="id" label="操作">
                    <template scope="scope">
                      <i
                        @click="removeLine(scope.row.uuid,index)"
                        class="el-icon-remove-outline"
                      ></i>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="getPos.length">
                <div class="add_lc" @click="add_lc(item,index)">
                  <span>
                    <i class="el-icon el-icon-plus"></i>
                  </span>
                  <b>作業追加</b>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="submitBox">
        <span @click="submit">提出</span>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="45%">
      <el-form
        :inline="true"
        :rules="rules"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="職業を選択して下さい" prop="add">
          <el-select
            v-model="formInline.add"
            size="small"
            @change="handelAddChange"
            placeholder="選択して下さい"
          >
            <el-option
              v-for="item in list"
              :key="item.id"
              :label="item.position"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="メンバーを選択して下さい" prop="people">
          <el-select
            v-model="formInline.people"
            size="small"
            @change="peopleChange"
            placeholder="選択して下さい"
          >
            <el-option
              v-for="item in userArr"
              :key="item.userid"
              :label="item.nickname"
              :value="item.userid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取　消</el-button>
        <el-button type="primary" @click="addPageUser_">確　定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/service";
// import Sortable from 'sortablejs'
export default {
  data() {
    return {
      caseInfo: {},
      list: [],
      pageList: [],
      isSelected: [],
      sortArr: [],
      selected_: [],
      dialogVisible: false,
      formInline: {
        add: "",
        people: "",
      },
      isSet: false,
      userArr: [],
      userList: [],
      currentData: [],
      currentIndex: '',
      getPos: [],
      rules: {
        add: [{ required: true, message: "選択して下さい", trigger: "change" }],
        people: [
          { required: true, message: "選択して下さい", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.caseInfo = JSON.parse(this.$route.query.data);
    const params = {
      projectid: this.caseInfo.projectid,
      chapterid: this.caseInfo.id,
    };
    this.chapterProcedure(params);
    console.log(this.getPos,"9696")
  },
  watch: {
    dialogVisible(val) {
      if(!val) {
        this.formInline.add = ''
        this.formInline.people = ''
      }
    }
  },
  methods: {
    //   级联菜单
    handelAddChange() {
      this.userList = this.list.filter((item) => {
        return item.id == this.formInline.add;
      });
      this.userArr = this.userList[0].userarr;
      this.formInline.people = "";
    },
    filterUser(id) {
      let label = "";
      this.list.forEach((item) => {
        if (id == item.id) {
          label = item.position;
        }
      });
      return label;
    },
    peopleChange() {

    },
    //追加工作流程
    add_lc(data,index) {
      console.log(data)
      if(data.status != 1) {
        this.$message.error("作業中の為、変更は出来ません")
        return
      }
      this.currentData = data;
      this.dialogVisible = true;
      this.currentIndex = index
    },
    handelChange() {
    this.selected_ = []
    this.isSelected.forEach(item => {
      this.list.filter(v => {
        if(item == v.id){
          this.selected_.push(v)
        }
      })
    })
    },
    addPageUser_() {
      const obj = {
        checked: this.formInline.people,
        pos: this.userList[0].position,
        posid: this.formInline.add,
        userArr: this.userList[0].userarr,
      }

      if (this.formInline.people && this.formInline.add) {
        // this.addPageUser(params);
        this.getPos[this.currentIndex].pos.push(obj)
        this.getPos.forEach((item,index) => {
            item.pos = item.pos.map((el,num) => {
              return {
                ...el,
                uuid: 'pos'+ index + 'line' + num
              }
            })
          })
        this.dialogVisible = false
        this.userList = []
      } else {
        this.$message.error("必須項目が全て入力された事を確認して下さい");
      }
    },
    addPageUser(data) {
      service.personal
        .addPageUser(data)
        .then((res) => {
          if (res.status === 200) {
            this.dialogVisible = false;
            this.$message.success("作業フローが追加されました");
            const params = {
              projectid: this.caseInfo.projectid,
              chapterid: this.caseInfo.id,
            };
            this.chapterProcedure(params);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    change(data, _index, _item, _name) {
      
    },
    //作业流程
    chapterProcedure(data) {
      service.personal.chapterProcedure(data).then((res) => {
        if (res.status === 200 && res.data.length) {
          this.list = res.data;
          this.list = this.list.filter(item => {
            return item.id !== 5 && item.id !== 4 && item.id !== 1 && item.id !== 13
          })
          this.pageList = res.page;
          this.getPos = res.str ? JSON.parse(res.str) : []
          this.isSet = res.str ? true : false
          const listLen = this.list.length;
          this.pageList.forEach((item, index) => {
            const arr = Array.apply(null, Array(listLen)).map((item) => {
              return "";
            });
            item.listValue = arr;
            arr.forEach((_item, _index) => {
              this.$set(this.pageList[index], ["value" + _index], _item);
            });
          });
        } else {
          this.$warning("先に募集して下さい");
        }
      });
    },
    batch() {
      this.sortArr = this.selected_;
      let arr = this.sortArr.map(item => {
        return item.id
      }).join(',')
      const params = {
        projectid: this.caseInfo.projectid,
        chapterid: this.caseInfo.id,
        pos:arr
      }
      this.$confirm('全ての作業工程に一括反映しますか ?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          service.personal.chapterAutobatch(params).then(res => {
          if(res.status) {
          this.getPos = res.data
            this.getPos.forEach((item,index) => {
            item.pos = item.pos.map((el,num) => {
              return {
                ...el,
                uuid: 'pos'+ index + 'line' + num
              }
            })
          })
        }
      })
        }).catch(() => {});
      
    },
    removeLine(id,index) {
      this.$confirm("この作業を削除しますか?", "提示", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            this.getPos[index].pos = this.getPos[index].pos.filter(item => {
              return item.uuid !== id
            })
          }).catch(() => {

          });
      return
      if (parent.sortArr) {
        parent.sortArr = parent.sortArr.filter((item) => {
          return item.id != data.id;
        });
      } else {
        let params = {
          pageuserid: data.id,
          pageid: data.pageid,
        };
        this.$confirm("この作業を削除しますか?", "提示", {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.deletePageUser(params);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "削除を取消ました",
            });
          });
      }
    },
    deletePageUser(data) {
      service.personal
        .deletePageUser(data)
        .then((res) => {
          if (res.status === 200) {
            const params = {
              projectid: this.caseInfo.projectid,
              chapterid: this.caseInfo.id,
            };
            this.chapterProcedure(params);
            this.$message({
              type: "success",
              message: "削除しました!",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      let selectedArr = [];
      this.pageList.forEach((item) => {
        if (item.checked) {
          selectedArr.push(item);
        }
      });
      let params = [];
      this.getPos.forEach(item => {
        let obj = {}
        obj.pageid = item.id
        obj.data = []
        item.pos.forEach((i,index) => {
          let userObj = {}
          userObj.userid = i.checked
          userObj.posid = i.posid
          obj.data.push(userObj)
        })
        params.push(obj)
      })
      let isEmpty = params.every(item => {
        return item.data.length == 0
      })
      if(!params.length || isEmpty) {
        this.$message.error('作業工程を設定してから、提出して下さい')
        return
      }

      let allParams = {
        pagestr: params,
        projectid: this.caseInfo.projectid,
        chapterid: this.caseInfo.id,
        str: JSON.stringify(this.getPos)
      };
      
      this.setChapterProcedure(allParams);
    },
    setChapterProcedure(data) {
      service.personal.setChapterProcedure(data).then((res) => {
        this.$message.success(res.info);
        this.$router.go(-1)
      });
    },
  },
};
</script>
<style></style>
<style lang="scss" scoped>
.main {
  .title {
    display: flex;
    align-items: center;
    div:first-child {
      flex: 1;
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        cursor: pointer;
      }
      h2 {
        color: #333;
        margin-left: 15px;
      }
    }
    div:last-child {
      span {
        display: block;
        width: 200px;
        height: 50px;
        line-height: 50px;
        background: #f03747;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .content-box {
    margin-top: 20px;
    div.add_lc {
      cursor: pointer;
      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        background-color: #0051d9;
        line-height: 15px;
        text-align: center;
        margin-right: 5px;
        i {
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .top-setter {
      > div {
        display: flex;
        align-items: center;
        h3 {
          margin-right: 30px;
        }
      }
    }
    .set-content {
      display: flex;
      align-content: center;
      > div:first-child {
        flex: 1;
        /deep/ .el-select__tags {
          white-space: nowrap;
          overflow: hidden;
        }
      }
      margin-top: 20px;
      ul {
        li {
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          cursor: move;
          background-color: #eee;
          margin-top: 5px;
          padding: 0 5px;
        }
      }
    }
    .pages {
      margin-top: 30px;
      ul {
        display: flex;
        flex-wrap: wrap;
        > li {
          position: relative;
          width: 47%;
          // min-width: 260px;
          min-height: 240px;
          box-shadow: #ddd 3px 3px 4px;
          margin: 10px 10px;
          background-color: #f9f9f9;
          p.disabled {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #000;
            opacity: .5;
            z-index: 999;
            cursor: no-drop;
          }
          > div:first-child {
            line-height: 30px;
          }
          > div:nth-child(2) {
            i {
              font-size: 16px;
              cursor: pointer;
            }
          }
          > div:last-child {
            line-height: 30px;
          }
        }
      }
    }
  }
  .submitBox {
    display: flex;
    justify-content: center;
    span {
      display: block;
      width: 200px;
      height: 50px;
      line-height: 50px;
      background: #f03747;
      border-radius: 8px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
